




























































































































































































































































import Vue from 'vue';

export default Vue.extend({
  auth: true,
  data: () => ({
    selectedIssu: undefined,
    isSubscribed: false,
  }),
  computed: {
    tenant() {
      return this.$route.params.tenant;
    },
    workspace() {
      return this.$route.params.workspace;
    },
  },
  mounted() {
    this.isSubscribed = this.$service.api.subscriptions.getHasGastroJournalSubscription();
  },
  methods: {
    async phoneCall(phoneNumber: string) {
      const telLink = `tel:${phoneNumber}`;
      window.location.href = telLink;
    },
  },
});
